<template>
  <div class="page">
    <img src="./img/signin-bg.png" class="bg" />
    <div class="main">
      <div class="activity-name">{{ activityDetail.activityName }}</div>
      <div
        v-if="activityDetail.activityPattern != 1"
        class="picker-box"
        @click="dateShow = true"
      >
        <div class="picker-name">日期选择</div>
        <div class="picker-info">
          {{ date || "请选择日期" }}
        </div>
        <img src="./img/arrow-r.png" />
      </div>
      <div class="picker-box" @click="sessionShow = true">
        <div class="picker-name">场次选择</div>
        <div class="picker-info">{{ scheduleStr }}</div>
        <img src="./img/arrow-r.png" />
      </div>
      <div class="head-line">
        <div class="head-item" v-for="(item, index) in headList" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="list">
        <div class="list-item" v-for="(item, index) in signList" :key="index">
          <div class="item-info">
            {{ item.joinUserName ? item.joinUserName : "-" }}
          </div>
          <div class="item-info">
            {{ item.signTime ? item.signTime.slice(-8, -3) : "-" }}
          </div>
          <div class="item-info" v-if="activityDetail.activityPattern == 3">
            {{ item.signOutTime.slice(-8, -3) || "-" }}
          </div>
          <div class="item-info" v-if="activityDetail.activityPattern == 3">
            {{ serviceTimeFormat(item.serviceTime) }}
          </div>
          <div class="item-info">
            <div
              :class="['btn', format(item).className]"
              @click="signOrNot(item, format(item).str)"
            >
              {{ format(item).str }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 日期选择 -->
    <v-picker
      :columns="dateList"
      :valueShow="dateShow"
      :valueKey="eventKey"
      @clickOverlay="dateClickOverlay"
      @cancel="dateCancelPick"
      @confirmPicker="dateConfirm"
    ></v-picker>
    <!-- 场次选择 -->
    <v-picker
      :columns="sessionList"
      :valueShow="sessionShow"
      :valueKey="eventKey"
      @clickOverlay="sessionClickOverlay"
      @cancel="sessionCancelPick"
      @confirmPicker="sessionConfirm"
    ></v-picker>
  </div>
</template>

<script>
import {
  getScheduleSignDetailsUrl,
  signInOrOutUrl,
  getScheduleListUrl,
  getActivityDetailUrl,
} from "./api.js";
export default {
  data() {
    return {
      type: 1,
      headList: [],
      signList: [],
      dateShow: false,
      sessionShow: false,
      eventKey: "text",
      activityDetail: {},
      dateList: [],
      date: null,
      sessionList: [],
      scheduleId: null,
      scheduleStr: null,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { activityId } = this.$route.query;
    this.activityId = activityId;
  },
  mounted() {
    this.getActivityDetail();
  },
  methods: {
    serviceTimeFormat(num) {
      if (num == 0 || !num || num == "") {
        // return `${num}分钟`;
        return `-`;
      } else if (num > 0) {
        let h = Math.floor(num / 60);
        let m = num - h * 60;
        return `${h}h${m}m`;
      }
    },
    signOrNot(item, str) {
      if (item.isOver == 1) {
        this.$toast({
          message: "该场次已结束",
          duration: 500,
        });
        return;
      }
      if (str == "签到" || str == "签出") {
        let params = {
          id: item.id,
          type: null,
        };
        if (item.isSign == 0) {
          params.type = "1";
        } else if (
          item.isSign == 1 &&
          this.activityDetail.activityPattern == 3 &&
          this.activityDetail.activityStatus != 5
        ) {
          params.type = "2";
        }
        this.$axios
          .post(`${signInOrOutUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              this.getSignList();
            }
          });
      }
    },
    getAllDateCN(startTime, endTime) {
      var date_all = [];
      var i = 0;
      while (endTime.getTime() - startTime.getTime() >= 0) {
        var year = startTime.getFullYear();
        var month = startTime.getMonth() + 1;
        var day = startTime.getDate();
        date_all[i] = `${year}-${month >= 10 ? month : "0" + month}-${
          day >= 10 ? day : "0" + day
        }`;
        startTime.setDate(startTime.getDate() + 1);
        i += 1;
      }
      // console.log(date_all);
      return date_all;
    },
    getActivityDetail() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
      };
      this.$axios.get(`${getActivityDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.activityDetail = res.data;
          if (this.activityDetail.activityPattern == 3) {
            this.headList = [
              "姓名",
              "签到时间",
              "签出时间",
              "服务时长",
              "状态",
            ];
          } else {
            this.headList = ["姓名", "签到时间", "状态"];
          }
          let list = this.getAllDateCN(
            new Date(this.activityDetail.startDate.replace(/-/g, "/")),
            new Date(this.activityDetail.endDate.replace(/-/g, "/"))
          );
          list.forEach((ele) => {
            this.dateList.push({ text: ele, value: ele });
          });
          if (
            this.dateList.length > 0 &&
            this.activityDetail.activityPattern != 1
          ) {
            this.date = this.dateList[0].value;
            this.getScheduleList();
          } else {
            this.date = null;
            this.getScheduleList();
          }
        }
      });
    },
    getScheduleList() {
      let params = {
        activityId: this.activityId,
        date: this.date,
      };
      // 先清空
      this.scheduleId = "";
      this.scheduleStr = "";
      this.$axios
        .get(`${getScheduleListUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.sessionList = [];
            if (this.activityDetail.activityPattern == 1) {
              res.data.forEach((ele) => {
                ele.value = ele.id;
                ele.text = `${ele.startDate}~${ele.endDate}`;
                this.sessionList.push(ele);
              });
            } else {
              res.data.forEach((ele) => {
                ele.value = ele.id;
                ele.text = ele.dayTimeBucket;
                this.sessionList.push(ele);
              });
            }
            this.scheduleId = this.sessionList[0].id;
            this.scheduleStr = this.sessionList[0].text;
            this.getSignList();
          }
        });
    },
    getSignList() {
      let params = {
        scheduleId: this.scheduleId,
      };
      this.$axios
        .get(`${getScheduleSignDetailsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.signList = res.data;
          }
        });
    },
    dateClickOverlay() {
      this.dateShow = false;
    },
    dateCancelPick() {
      this.dateShow = false;
    },
    dateConfirm(value) {
      this.date = value.text;
      this.dateShow = false;
      this.getScheduleList();
    },
    sessionClickOverlay() {
      this.sessionShow = false;
    },
    sessionCancelPick() {
      this.sessionShow = false;
    },
    sessionConfirm(value) {
      this.scheduleId = value.id;
      this.scheduleStr = value.text;
      this.getSignList();
      this.sessionShow = false;
    },
    format(item) {
      /*
        1. isSign = 0 签到状态为 “未签到” 时
        1.1 按钮显示为签到
        
        2. isSign = 1 签到状态为 “已签到” 时
        2.1 如果当前场次已结束，按钮显示为 已签
        2.2 如果当前场次未结束、模式为定期模式（时间银行）， 按钮显示为 签出
        2.3 如果当前场次未结束、模式不为定期模式（非时间银行），按钮显示为 已签
        2.4 其余情况无返回

        3. isSign = 2 签到状态为 “已签出” 时
        3.1 按钮显示 已签出

        4. isSign = 10 签到状态为 “未开始” 时
        4.1 按钮显示为未开始

        5. isSign = 99 签到状态为 “缺席” 时
        5.1 按钮显示为缺席
      */
      // 签到状态
      const status = item.isSign;
      // 时间状态 0 表示正常 1 表示已结束
      const isOver = item.isOver;
      // 优先判断场次是否已结束
      if (status == 0) {
        return { className: "blue", str: "签到" };
      } else if (status == 1) {
        if (isOver == 1) {
          // 场次结束
          return { className: "white", str: "已签" };
        } else if (isOver == 0 && this.activityDetail.activityPattern == 3) {
          return { className: "yellow", str: "签出" };
        } else if (isOver == 0 && this.activityDetail.activityPattern != 3) {
          return { className: "white", str: "已签" };
        }
      } else if (status == 2) {
        return { className: "black", str: "已签出" };
      } else if (status == 10) {
        return { className: "grey", str: "未开始" };
      } else if (status == 99) {
        return { className: "black", str: "缺席" };
      }
    },
    // format(item) {
    //   // 签到状态
    //   const status = item.isSign;
    //   // 时间状态 0 表示正常 1 表示已结束
    //   const isOver = item.isOver;
    //   // 优先判断场次是否已结束
    //   if (isOver == 1 && status == 1) {
    //     return { className: "white", str: "已签" };
    //   } else if (isOver == 1 && status == 99) {
    //     return { className: "black", str: "缺席" };
    //   }
    //   // 若场次未结束 走下面逻辑
    //   if (status == 10) {
    //     return { className: "grey", str: "未开始" };
    //   } else if (status == 0) {
    //     return { className: "blue", str: "签到" };
    //   } else if (
    //     status == 1 &&
    //     this.activityDetail.activityPattern == 3 &&
    //     this.activityDetail.activityStatus != 5
    //   ) {
    //     return { className: "yellow", str: "签出" };
    //   } else if (status == 99) {
    //     return { className: "black", str: "缺席" };
    //   } else if (
    //     (status == 1 && this.activityDetail.activityPattern != 3) ||
    //     (status == 1 && this.activityDetail.activityStatus == 5)
    //   ) {
    //     return { className: "white", str: "已签" };
    //   }
    // },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 0 60px;
  .main {
    position: relative;
    padding: 48px 30px 0;
    box-sizing: border-box;
    .list {
      min-height: 678px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
      border-radius: 8px;
      padding-top: 42px;
      box-sizing: border-box;
      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        line-height: 50px;
        height: 50px;
        .item-info {
          flex: 1;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          justify-content: center;
          .btn {
            width: 120px;
            height: 50px;
            border-radius: 48px;
            font-size: 24px;
            text-align: center;
            font-weight: 600;
            line-height: 50px;
            box-sizing: border-box;
          }
          .grey {
            color: #aeaeae;
            background: #e5e5e5;
          }
          .blue {
            color: #ffffff;
            background: #538ef4;
          }
          .yellow {
            color: #ffffff;
            background: #ffce49;
          }
          .black {
            color: #ffffff;
            background: #adadad;
          }
          .white {
            background-color: transparent;
            color: #007eff;
            border: 2px solid #007eff;
          }
        }
      }
    }
    .head-line {
      height: 88px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      .head-item {
        text-align: center;
        flex: 1;
        font-size: 26px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .picker-box {
      height: 88px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
      border-radius: 8px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      padding: 0 24px 0 34px;
      box-sizing: border-box;
      line-height: 88px;
      .picker-name {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      img {
        width: 14px;
        margin-left: 10px;
      }
      .picker-info {
        flex: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        text-align: right;
        height: 100%;
      }
    }
    .activity-name {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      margin-bottom: 34px;
    }
  }
  .bg {
    width: 100%;
    position: absolute;
  }
}
</style>
